<template>
	<v-dialog max-width="450" :value="value" @input="$emit('input', $event)">
		<v-overlay :value="dialogLoadingData">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-form data-vv-scope="formSmsVerification" v-if="metadataLoaded">
			<v-card class="pa-3">
				<div class="text-center">
					<v-icon size="80">fas fa-mobile-alt</v-icon>
				</div>
				<div class="text-center headline">
					<h3>{{ $t("submitCode") }}</h3>
				</div>
				<v-card-text>
					<div class="subheading text-center">
						{{ $t("codeSent") }}
						{{ mobilePhoneNo }}.
					</div>
				</v-card-text>

				<v-text-field
					v-model="verificationCode"
					data-vv-scope="formSmsVerification"
					data-vv-delay="300"
					:label="$t('VerificationCode')"
					:data-vv-as="$t('VerificationCode')"
					data-vv-name="VerificationCode"
					:counter="6"
					v-validate="'required|numeric|min:6|max:6'"
					:error-messages="errors.collect('formSmsVerification.VerificationCode')"
					class="verificationCodeField"
				></v-text-field>
				<v-btn block class="mt-3" color="primary" @click="VerifyMobilePhone">{{
					$t("common.continue")
				}}</v-btn>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import Account from "@/services/account";

export default {
	props: {
		value: { type: Boolean, required: true },
		metadata: { type: Object },
		metadataLoaded: { type: Boolean },
		mobilePhoneNo: { type: String },
	},
	data: () => ({
		dialogLoadingData: false,
		verificationCode: "",
	}),
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async VerifyMobilePhone() {
			try {
				this.dialogLoadingData = true;
				const validationResult = await this.$validator.validateAll(
					"formSmsVerification"
				);
				if (!validationResult) {
					this.$log.info("form not valid");
					return;
				}

				const dto = {};
				dto.VerificationCode = this.verificationCode;
				await Account.VerifyMobilePhone(dto);

				this.snackSuccess({ Text: this.$t("smsVerificationComplete") });
				this.$emit("VerificationComplete");
			} catch (error) {
				this.$captureError(error);
				if (error.Message && error.Message === "Invalid Code") {
					this.snackError({ Text: this.$t("snackError.InvalidCode") });
				} else {
					this.snackError({ Text: this.$t("snackError.VerifyMobilePhone") });
				}
			} finally {
				this.dialogLoadingData = false;
			}
		},
	},
};
</script>

<style lang="scss">
.verificationCodeField {
	input {
		text-align: center;
		font-size: 24px;
		font-weight: bold;
	}
}
</style>

<i18n>
{
	"it": {
		"codeSent": "Abbiamo inviato un codice a 6 cifre al numero di telefono",
		"submitCode": "Immetti il codice",
		"snackError.VerifyMobilePhone": "Impossibile verificare il numero di telefono",
        "snackError.InvalidCode": "Codice non valido",
        "VerificationCode": "Codice di verifica",
        "smsVerificationComplete": "Numero di telefono verificato!"
    }
}
</i18n>
