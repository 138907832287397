<template>
	<div>
		<v-card class="LabelValueGrid elevation-5 pa-3">
			<v-row class="pa-2">
				<v-col cols="12" class="text-center">
					<v-breadcrumbs :items="breadcrumbsItems">
            <template v-slot:divider>
              <v-icon class="secondary--text text--lighten-2"
              >fas fa-caret-right</v-icon
              >
            </template>
          </v-breadcrumbs>

					<div class="d-flex justify-center mb-3">
						<user-avatar-fullname
							:size="100"
							:showDisplayName="false"
							:profile="profile"
						></user-avatar-fullname>
					</div>
				</v-col>
			</v-row>

			<v-tabs left>
				<v-tabs-slider color="secondary"></v-tabs-slider>
				<v-tab ripple>
					<span>{{ $t("personalData") }}</span>
				</v-tab>
				<v-tab-item>
					<PersonalData
						:metadata="metadata"
						:metadataLoaded="metadataLoaded"
						:profile="profile"
					></PersonalData>
				</v-tab-item>

				<v-tab ripple v-if="isUserInRole('Patient')">
					<span>{{ $t("billingInfo") }}</span>
				</v-tab>
				<v-tab-item v-if="isUserInRole('Patient')">
					<div class="mt-3">
						<UserBillingProfiles></UserBillingProfiles>
					</div>
				</v-tab-item>
			</v-tabs>
		</v-card>
	</div>
</template>

<script>
import PersonalData from "./PersonalData";
import UserBillingProfiles from "@/components/Account/UserBillingProfiles.vue";
import { mapActions, mapGetters } from "vuex";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";
import metadata from "@/mixins/metadata";
import Account from "@/services/account";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},

	components: {
		PersonalData,
		UserAvatarFullname,
		UserBillingProfiles,
	},
	mixins: [metadata],
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			showCroppaDialog: false,
		};
	},
	computed: {
		...mapGetters([
			"isUserInRole"
		]),

		isAuthenticated() {
			return this.$store.state.userData.isAuthenticated;
		},
		profile() {
			return this.$store.state.userData.profile;
		},
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Account/Profile",
		});
		await this.LoadMetadata(Account);
	},
	methods: {
		...mapActions(["LoadUserData", "snackSuccess", "snackError"]),
	},
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.profileImageBadge {
	.v-badge {
		&__badge {
			right: -3px;
			background: map-get($blue-grey, "lighten-4") !important;
			border: 1px solid map-get($blue-grey, "lighten-4") !important;
			border-radius: 50%;
			padding: 0;
			min-width: 0;
			height: 40px;
			width: 40px;
			.v-btn {
				border-radius: 50%;
				padding: 0;
				min-width: 0;
				height: 38px;
				width: 38px;
				&:before {
					display: none;
				}
			}
			.v-icon {
				color: map-get($grey, "darken-1");
				font-size: 22px;
			}
		}
	}
}
</style>

<i18n>
{
	"it": {
		"pageTitle": "Profilo",
		"pageMetaDescription": "Profilo",
		"personalData": "Dati Personali",
		"billingInfo": "Dati di Fatturazione",
		"VerificationCode": "Codice di verifica",
		"verifiedNumber": "Numero verificato",
		"smsVerificationComplete": "Il tuo numero e' stato verificato.",
		"smsVerificationCannotSendSMS": "Cannot send SMS",
		"billingInfo": "Dati di fatturazione"
	},
	"en": {
		"title": "Profile",
		"label_NameSurname": "Name Surname",
		"label_Password": "Password",
		"label_Mobile": "Mobile No",
		"label_Email": "Email",
		"button_UploadImage": "Add Photo",
		"uploadDialog_Title": "Upload your profile image",
		"MobilePhone": "Your mobile phone number",
		"VerificationCode": "Your verification code",
		"smsVerificationComplete": "Your phone number is verified"
	}
}
</i18n>
